import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import '../styles/add.css';

const ModalAddProducto = ({ show, handleClose, handleShowBanner }) => {
  const [imagenes, setImagenes] = useState(['']);
  const [video, setVideo] = useState('');
  const [nombre, setNombre] = useState('');
  const [colores, setColores] = useState([{ color: '', stock: '' }]);
  const [talles, setTalles] = useState([{ talle: '', stock: '' }]);
  const [precio, setPrecio] = useState('');
  const [stockGeneral, setStockGeneral] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [error, setError] = useState('');

  const handleImagenesChange = (index, value) => {
    const newImagenes = [...imagenes];
    newImagenes[index] = value;
    setImagenes(newImagenes);
  };

  const handleAddImagen = () => {
    setImagenes([...imagenes, '']);
  };

  const handleRemoveImagen = (index) => {
    const newImagenes = imagenes.filter((_, i) => i !== index);
    setImagenes(newImagenes);
  };

  const handleColorChange = (index, field, value) => {
    const newColores = [...colores];
    newColores[index][field] = value;
    setColores(newColores);
  };

  const handleAddColor = () => {
    setColores([...colores, { color: '', stock: '' }]);
  };

  const handleRemoveColor = (index) => {
    const newColores = colores.filter((_, i) => i !== index);
    setColores(newColores);
  };

  const handleTalleChange = (index, field, value) => {
    const newTalles = [...talles];
    newTalles[index][field] = value;
    setTalles(newTalles);
  };

  const handleAddTalle = () => {
    setTalles([...talles, { talle: '', stock: '' }]);
  };

  const handleRemoveTalle = (index) => {
    const newTalles = talles.filter((_, i) => i !== index);
    setTalles(newTalles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const newProduct = {
        imagenes,
        video,
        nombre,
        colores,
        talles,
        precio: parseFloat(precio),
        stockGeneral: parseInt(stockGeneral),
        descripcion,
        status: true
      };

      await axios.post(
        'https://ecommerce-backend-self-phi.vercel.app/productos',
        newProduct,
      );
      alert('Producto agregado');
      handleShowBanner();
      handleClose();
    } catch (error) {
      console.error('Error adding product:', error);
      setError('Hubo un error al agregar el producto.');
    }
  };

  return (
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formImagenes">
          <Form.Label>Imágenes (URLs)</Form.Label>
          {imagenes.map((img, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <Form.Control
                type="text"
                placeholder="URL de la imagen"
                value={img}
                onChange={(e) => handleImagenesChange(index, e.target.value)}
              />
              <Button
                variant="danger"
                onClick={() => handleRemoveImagen(index)}
                style={{ marginTop: '5px' }}
                className='btn-add'
              >
                Eliminar
              </Button>
              <Button variant="primary" className='btn-add add' onClick={handleAddImagen}>
            Agregar Imagen
          </Button>
            </div>
          ))}
          
        </Form.Group>
        <Form.Group controlId="formVideo">
          <Form.Label>Video (URL)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese URL del video"
            value={video}
            onChange={(e) => setVideo(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formNombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese nombre del producto"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </Form.Group>
        {colores.map((col, index) => (
          <div key={index}>
            <Form.Group controlId={`formColor${index}`}>
              <Form.Label>Color</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese color del producto"
                value={col.color}
                onChange={(e) =>
                  handleColorChange(index, 'color', e.target.value)
                }
                required
              />
            </Form.Group>
            <Form.Group controlId={`formStockColor${index}`}>
              <Form.Label>Stock de Color</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese stock del color"
                value={col.stock}
                onChange={(e) =>
                  handleColorChange(index, 'stock', e.target.value)
                }
                required
              />
            </Form.Group>
            <Button variant="danger" className='btn-add add' onClick={() => handleRemoveColor(index)}>
              Eliminar Color
            </Button>
            <Button variant="secondary" className='btn-add add' onClick={handleAddColor}>
          Agregar Color
        </Button>
          </div>
        ))}
        
        {talles.map((talle, index) => (
          <div key={index}>
            <Form.Group controlId={`formTalle${index}`}>
              <Form.Label>Talle</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese talle del producto"
                value={talle.talle}
                onChange={(e) =>
                  handleTalleChange(index, 'talle', e.target.value)
                }
              />
            </Form.Group>
            <Form.Group controlId={`formStockTalle${index}`}>
              <Form.Label>Stock de Talle</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ingrese stock del talle"
                value={talle.stock}
                onChange={(e) =>
                  handleTalleChange(index, 'stock', e.target.value)
                }
              />
            </Form.Group>
            <Button variant="danger" className='btn-add add' onClick={() => handleRemoveTalle(index)}>
              Eliminar Talle
            </Button>
            <Button variant="secondary" className='btn-add add' onClick={handleAddTalle}>
          Agregar Talle
        </Button>
          </div>
        ))}
      
        <Form.Group controlId="formPrecio">
          <Form.Label>Precio</Form.Label>
          <Form.Control
            type="number"
            placeholder="Ingrese precio del producto"
            value={precio}
            onChange={(e) => setPrecio(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formStockGeneral">
          <Form.Label>Stock General</Form.Label>
          <Form.Control
            type="number"
            placeholder="Ingrese stock general del producto"
            value={stockGeneral}
            onChange={(e) => setStockGeneral(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formDescripcion">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ingrese descripción del producto"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" className='btn-add add' type="submit">
          Agregar Producto
        </Button>
      </Form>
    </Modal.Body>
  );
};

export default ModalAddProducto;
